import React, { useState } from 'react';

const CheckboxComponent = ({ text, disabled }) => {
  const [checked, setChecked] = useState(false)

  return (
    <label className="flex items-center space-x-3">
      <input
        disabled={disabled}
        type="checkbox"
        className={`${checked
          ? 'ring-green-500 border-green-500 bg-green-500 text-white'
          : 'ring-0 border-green-500 bg-white text-gray-400'
          } relative rounded h-5 w-5 accent-green-50 focus:accent-green-300`}
        checked={checked}
        onChange={e => setChecked(e.target.checked)}
      />
      <span className="text-gray-900 font-medium select-none">{text}</span>
    </label>

  )
}

const App = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [checked, setChecked] = useState(false)

  const closeModal = () => {
    setIsOpen(false);
  }

  const openModal = () => {
    setIsOpen(true);
  }

  return (
    <div className='App'>
      <div class="flex justify-center items-center min-h-screen">
        <button
          className='bg-green-500 text-white active:bg-blue-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1'
          type='button'
          onClick={openModal}
        >
          Click to Personalize
        </button>
      </div>

      {isOpen && (
        <div className='fixed z-10 inset-0 overflow-y-auto'>
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <div onClick={closeModal} className='fixed inset-0 transition-opacity' aria-hidden='true'>
              <div className='absolute inset-0 bg-gray-500 opacity-75'></div>
            </div>
            <span className='hidden sm:inline-block sm:align-middle sm:h-screen' aria-hidden='true'>&#8203;</span>
            <div
              className=' inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle w-[800px] border-green-500 border-2'
              role='dialog'
              aria-modal='true'
              aria-labelledby='modal-headline'
            >
              <div className='bg-custom-green flex justify-between w-full px-5 py-4'>
                <p className='text-white'>Personalization Settings:</p>
                <div className='flex gap-3'>
                  <p className='text-white'>Use Account Reasearch</p>
                  <label htmlFor="toggle" className="flex items-center cursor-pointer">
                    <div className="relative">
                      <input type="checkbox" id="toggle" className="sr-only" checked={checked}
                        onChange={() => setChecked(!checked)} />
                      <div className={`bg-gray-200 w-10 h-5 rounded-full shadow-inner ${checked ? "" : ""}`}></div>
                      <div className={`absolute w-4 h-4 top-0.5 bg-custom-green rounded-full shadow inset-y-0 left-0 transform transition-transform duration-200 ${checked ? "translate-x-5" : ""}`}></div>
                    </div>
                  </label>
                </div>
              </div>
              <div className='bg-white p-10'>
                <div className='flex-col'>
                  <p className='text-black text-xl'>Profile:</p>
                  <div className='flex justify-between'>
                    <div className='flex-col space-y-3 p-2'>
                      <CheckboxComponent text="Linkedin Bio" />
                      <CheckboxComponent text="Years of experience" />
                      <CheckboxComponent text="Current Experience" />
                    </div>
                    <div className='flex-col space-y-3 p-2'>
                      <CheckboxComponent text="List of past jobs" />
                      <div className='flex'>
                        <CheckboxComponent text="Current Job description" disabled={true} />
                        <span className="inline-flex bg-red-50 text-red-400 px-2 py-0.5 text-xs font-bold rounded-2xl items-center justify-center">
                          Disabled
                        </span>
                      </div>
                      <div className='flex'>
                        <CheckboxComponent text="Current Job description" disabled={true} />
                        <span className="inline-flex bg-red-50 text-red-400 px-2 py-0.5 text-xs font-bold rounded-2xl items-center justify-center">
                          Disabled
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="border-b border-custom-green my-8"></div>

                <div className='flex-col'>
                  <p className='text-black text-xl'>Account:</p>
                  <div className='flex justify-between'>
                    <div className='flex-col space-y-3 gap-5 p-2'>
                      <CheckboxComponent text="Linkedin Bio" />
                      <CheckboxComponent text="Years of experience" />
                      <CheckboxComponent text="Current Experience" />
                    </div>
                    <div className='flex-col space-y-3 p-2'>
                      <CheckboxComponent text="List of past jobs" />
                      <div className='flex'>
                        <CheckboxComponent text="Current Job description" disabled={true} />
                        <span className="inline-flex bg-red-50 text-red-400 px-2 py-0.5 text-xs font-bold rounded-2xl items-center justify-center">
                          Disabled
                        </span>
                      </div>
                      <div className='flex'>
                        <CheckboxComponent text="Current Job description" disabled={true} />
                        <span className="inline-flex bg-red-50 text-red-400 px-2 py-0.5 text-xs font-bold rounded-2xl items-center justify-center">
                          Disabled
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default App;
